<template>
  <div class="page">
    <div class="form">
      <div class="title">参会报名</div>
      <el-form
          :model="form"
          :rules="rules"
          ref="ruleFormRef"
      >
        <el-form-item label-width="80px" label="企业名称" required prop="company_name">
          <el-input v-on=" { change:companyNameChange } " v-model="form.company_name" placeholder="请输入企业名称" />
        </el-form-item>
        <el-form-item label-width="80px" label="姓名" required prop="name">
          <el-input  v-model="form.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label-width="80px" label="部门" required prop="bumen">
          <el-input  v-model="form.bumen" placeholder="请输入部门" />
        </el-form-item>
        <el-form-item label-width="80px" label="职位" required prop="zhiwu">
          <el-input  v-model="form.zhiwu" placeholder="请输入职位" />
        </el-form-item>
        <el-form-item label-width="80px" label="手机号" required prop="lxfs">
          <el-input v-model="form.lxfs" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label-width="80px" label="Email" prop="email">
          <el-input v-model="form.email" placeholder="请输入Email" />
        </el-form-item>
        <el-form-item>
          <el-button style="width: 120px;margin: 0 auto;margin-top: 50px" round type="primary" @click="onSubmit"> 提交 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="copyright">Copyright © 药友之家, All Rights Reserved. 成都卓康商务信息咨询有限公司 版权所有 蜀ICP备14002080号</div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router';

const ruleFormRef = ref();
const router = useRouter()

const rules = reactive({
  company_name: [
    { required: true, message: '请输入企业名称', trigger: 'blur' },
  ],
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' },
  ],
  bumen: [
    { required: true, message: '请输入部门', trigger: 'blur' },
  ],
  zhiwu: [
    { required: true, message: '请输入职位', trigger: 'blur' },
  ],
  lxfs: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
  ]
})

// do not use same name with ref
const form = reactive({
  company_name: '',
  name: '',
  bumen: '',
  zhiwu: '' ,
  lxfs: '',
  email: '',
  is_eve: '都不参加'
})
const onSubmit = () => {
  setTimeout(function (){
    router.push("/here_we_are")
  },1500)
  ruleFormRef.value.validate((res) => {
    if (res) {
      axios.post('attend_expo/add_expo',form).then((res)=>{
        if (res.data.code == 200){
          ruleFormRef.value.resetFields()
          ElMessage.success(res.data.msg);
          setTimeout(function (){
            router.push("/here_we_are")
          },1500)
        }else {
          ElMessage.error(res.data.msg);
        }
      })
    }
  });
}
</script>

<style scoped>
.page{
  width: 100%;
  /*height: 100%;*/
  position: absolute;
  background: #f1f1f1f1;
}
*{
  margin: 0;
  padding: 0;
}
.el-form-item{
  display: flex;
  font-size: 14px;
  margin-bottom: 18px;
}
.title{
  text-align: center;
  margin-bottom: 20px;
}
.form{
  padding: 25px 20px;
  margin: 25px 20px;
  border-radius: 15px;
  background: #ffffff;
  position: relative;
}
.copyright{
  margin: 0 auto;
  width: 280px;
  text-align: center;
  font-size: 1px;
  line-height: 16px;
  color: black;
  opacity: 0.3;
  position: relative;
  bottom: -80px;
}
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.tps{
  margin-left: 15px;
  color: #a8abb2;
}
</style>