<template>
  <div class="page">
    <div class="form">
      <div class="title">签到</div>
      <el-form
          :model="form"
          :rules="rules"
          ref="ruleFormRef"
      >
        <el-form-item label="查询" required prop="nameor_phone">
          <el-input v-model="form.nameor_phone" placeholder="请输入姓名或手机号码" />
        </el-form-item>

        <div v-if="is_show_attend">
          <div class="attend_title">参会人员信息</div>
          <div v-if="attend.is_are != 1">
            <el-form-item label="部门:">
              {{ attend.bumen }}
            </el-form-item>
            <el-form-item label="职位:">
              {{ attend.zhiwu }}
            </el-form-item>
            <el-form-item label="手机号码:">
              {{ attend.lxfs }}
            </el-form-item>
            <el-form-item label="email:">
              {{ attend.email }}
            </el-form-item>
          </div>
          <el-form-item label="姓名:">
            {{ attend.name }}
          </el-form-item>
          <el-form-item label="企业名称">
            {{ attend.company_name }}
          </el-form-item>
          <el-form-item label="是否参加晚宴:">
            <div v-if="attend.is_eve == 0">
              否
            </div>
            <div v-else style="color: red">
              <span>{{ attend.is_eve }}</span>
            </div>
          </el-form-item>
          <el-form-item label="座位安排:">
            <div style="color: red">
              {{ attend.seat }}
            </div>
          </el-form-item>
          <el-form-item label="是否签到:">
            <div v-if="attend.is_are == 1" style="color: green">
              已签到
            </div>
            <div v-else>
              未签到
            </div>
          </el-form-item>
          <div v-if="attend.is_are == 1">
            <el-form-item label="签到时间:">
              {{ attend.updated_at }}
            </el-form-item>
          </div>
        </div>

        <div v-if="is_not_query" class="not_query">
          <div>未查到相关参会人员信息！</div>
          <el-link type="primary" href="https://demand.yaoyouzhijia.com/#/add_are">去报名</el-link>
        </div>

        <el-form-item>
          <el-button style="width: 120px;margin: 0 auto;margin-top: 50px" :loading="search_loading" round type="primary" @click="onSearch"> 查询 </el-button>
          <el-button style="width: 120px;margin: 0 auto;margin-top: 50px" :loading="submit_loading" round type="primary" @click="onSubmit"> 签到 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="copyright">Copyright © 药友之家, All Rights Reserved. 成都卓康商务信息咨询有限公司 版权所有 蜀ICP备14002080号</div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'

const ruleFormRef = ref();

const rules = reactive({
  nameor_phone: [
    { required: true, message: '请输入姓名或手机号码', trigger: 'blur' },
  ]
})
const is_show_attend = ref(false);
const is_not_query = ref(false);
const search_loading = ref(false);
const submit_loading = ref(false);
const form = reactive({
  nameor_phone: ''
})
const attend = reactive({
  company_name: '',
  name: '',
  bumen: '',
  zhiwu: '',
  lxfs: '',
  email: '',
  is_eve: '',
  is_are: '',
  seat: '',
  updated_at: ''
})

const onSubmit = () => {
  ruleFormRef.value.validate((res) => {
    if (res) {
      submit_loading.value = true;

      ElMessageBox.confirm(
          '确认签到?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
      .then(() => {
        axios.post('attend_expo/expo',form).then((res)=>{
          submit_loading.value = false;
          if (res.data.code == 200){
            ElMessageBox.alert(res.data.msg, '提示', {
              confirmButtonText: '确定'
            })
            if (res.data.data){
              attend.company_name = res.data.data.company_name;
              attend.name = res.data.data.name;
              attend.bumen = res.data.data.bumen;
              attend.zhiwu = res.data.data.zhiwu;
              attend.lxfs = res.data.data.lxfs;
              attend.email = res.data.data.email;
              attend.is_eve = res.data.data.is_eve;
              attend.is_are = res.data.data.is_are;
              attend.seat = res.data.data.seat;
              attend.updated_at = res.data.data.updated_at;
              is_show_attend.value = true;
              is_not_query.value = false;
            }
          }else {
            ElMessage.error(res.data.msg);
          }
        })
      }).catch(() => {
        submit_loading.value = false;
      })
    }
  });
}

const onSearch = () => {
  ruleFormRef.value.validate((res) => {
    if (res) {
      search_loading.value = true;
      axios.get('attend_expo/search_expo?nameor_phone='+ form.nameor_phone).then((res)=>{
        search_loading.value = false;
        if (res.data.code == 200){
          if (res.data.data){
            attend.company_name = res.data.data.company_name;
            attend.name = res.data.data.name;
            attend.bumen = res.data.data.bumen;
            attend.zhiwu = res.data.data.zhiwu;
            attend.lxfs = res.data.data.lxfs;
            attend.email = res.data.data.email;
            attend.is_eve = res.data.data.is_eve;
            attend.is_are = res.data.data.is_are;
            attend.seat = res.data.data.seat;
            attend.updated_at = res.data.data.updated_at;
            is_show_attend.value = true;
            is_not_query.value = false;
          }else {
            is_show_attend.value = false;
            is_not_query.value = true;
          }
        }else {
          ElMessage.error(res.data.msg);
        }
      })
    }
  });
}
</script>

<style scoped>
.page{
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f1f1f1f1;
}
*{
  margin: 0;
  padding: 0;
}
.el-form-item{
  display: flex;
  font-size: 14px;
  margin-bottom: 18px;
}
.title{
  text-align: center;
  margin-bottom: 20px;
}
.form{
  padding: 25px 20px;
  margin: 25px 20px;
  border-radius: 15px;
  background: #ffffff;
  position: relative;
}
.copyright{
  margin: 0 auto;
  width: 280px;
  text-align: center;
  font-size: 1px;
  line-height: 16px;
  color: black;
  opacity: 0.3;
  position: relative;
  bottom: -80px;
}
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.attend_title{
  text-align: center;
  margin-bottom: 20px;
}
.not_query{
  text-align: center;
  line-height: 60px;
}
</style>