import { createRouter, createWebHashHistory } from 'vue-router';
import demand from '@/pages/DemandWall.vue'
import sub_demand from '@/pages/SubDemand.vue'
import here_we_are from '@/pages/HereWeAre.vue'
import add_are from '@/pages/AddAre.vue'

// 定义路由
const routes = [
    {
        path: '/demand',
        name: 'demand',
        meta: {
            title: '需求展示'
        },
        component: demand
    },
    {
        path: '/sub_demand',
        name: 'sub_demand',
        meta: {
            title: '发布需求'
        },
        component: sub_demand
    },
    {
        path: '/here_we_are',
        name: 'here_we_are',
        meta: {
            title: '签到'
        },
        component: here_we_are
    },
    {
        path: '/add_are',
        name: 'add_are',
        meta: {
            title: '参会报名'
        },
        component: add_are
    },
    {
        path:'/',
        redirect:'/demand'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});
export default router;