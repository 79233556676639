<template>
  <div class="page">
    <div class="head">
      <div class="head-left">
        <div>
          <div class="head-left-title">
            <el-image style="width: 550px; height: 82px" src="/images/title.png" />
          </div>
        </div>
      </div>
      <div class="head-center">企 业 需 求</div>
      <div class="head-right">
        <div class="qr-title">扫码发布</div>
        <div>
          <el-image style="width: 130px; height: 130px" src="/images/qrcode123.png" />
        </div>
      </div>
    </div>
    <div class="content shadow">
      <vue3-seamless-scroll :list="list.values" :step="10" limitScrollNum="1" singleHeight="600" singleWaitTime="20000">
        <div class="item" v-for="(item, index) in list.values" :key="index">
          <div class="item-box">
            <div class="company-name">{{ item.company_name }}</div>
            <div class="demand">
              <div class="box-left">
                <div class="demand-box">
                  <div class="demand-content-box">
                    <span class="title-color">需求方向：</span>
                    <div class="demand-content">{{ item.title }}</div>
                  </div>
                  <div class="demand-content-box">
                    <span class="title-color">需求介绍：</span>
                    <div style="white-space: pre-line" class="demand-content" v-html="item.desc"></div>
                  </div>
                </div>
              </div>
              <div class="box-right">
                <div class="box-right-title">联系方式</div>
                <div class="contact">{{ item.phone }}</div>
                <div class="wechat-qrcode" v-if="item.qrcode">
                  <div>
                    <el-image style="width: 200px; height: 266px" :src="item.qrcode" />
                  </div>
                  <div class="qrcode-title">微信二维码</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue3-seamless-scroll>
    </div>
    <div class="foot-info">
      <div class="foot-info-title">以推动医药项目精准对接，实现合作签约为目的</div>
      <div class="foot-info-content">扫描二维码填写您企业的需求如药品引进/转让、药厂收购、委托生产/研发、承接生产/研发等等，您的需求信息将在会场的大屏幕上展示，让有资源的企业主动联系您，创造更多合作机会!</div>
    </div>
    <div class="copyright">Copyright © 药友之家, All Rights Reserved. 成都卓康商务信息咨询有限公司 版权所有 蜀ICP备14002080号</div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import axios from "axios";

export default {
  components: {
    Vue3SeamlessScroll,
  },
  setup() {
    const state = reactive({
      list: {}
    });
    function getDemand() {
      axios.get('demand/get_demand_list').then((res)=>{
        if (res.data.code == 200){
          state.list.values = res.data.data
        }
      })
    }

    function addDemand() {
      axios.get('demand/add_demand_list').then((res)=>{
        if (res.data.code == 200){
          res.data.data.forEach(function (item1) {
            state.list.values.push(item1)
          })
        }
      })
    }
    return {
      ...toRefs(state), getDemand, addDemand
    };
  },
  mounted() {
    var that = this;
    this.getDemand()

    setInterval(function (){
      // that.addDemand()
      that.getDemand()
    },1000 * 60)
  }
};
</script>

<style>
  *{
    margin: 0;
    padding: 0;
  }
  .page{
    width: 100%;
    height: 100vh;
    position: absolute;
    /*background: linear-gradient(230deg, #FE0A51, #4003FE, #1FA9E9);*/
    background: url("http://api.yaoyouzhijia.com/storage/2023-11-10-03-08-35.jpg");
  }
  .head{
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
  }
  .head-left{
    width: 448px;
    margin-top: 41px;
  }
  .head-left-title{
    margin-left: 30px;
  }
  .head-left-title-en{
    text-align: center;
    font-size: 1px;
    color: #FFFFFF;
    opacity: 0.5;
  }
  .head-center{
    font-size: 53px;
    font-weight: 900;
    padding-top: 35px;
    padding-right: 185px;
  }
  .head-right{
    width: 228px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .qr-title{
    width: 34px;
    background: #FFDB5E;
    border-radius: 22px;
    color: black;
    margin: 0 5px;
    font-weight: bold;
    font-size: 21px;
    padding: 7px 2px;
  }
  .content{
    height: 615px;
    padding: 0 20px;
    margin: 20px 80px;
    background: #FFFFFF;
    border-radius: 11px;
    overflow:hidden;
    position: relative;
  }
  .item-box{
    height: 600px;
    overflow:hidden;
    position: relative;
  }
  .company-name{
    font-size: 25px;
    font-weight: bold;
    color: #AC010A;
    margin-top: 20px;
    text-align: center;
  }
  .demand{
    margin-top: 20px;
    overflow:hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
  }
  .demand-box{
    box-shadow: 0 2px 9px 1px rgba(0,27,75,0.42);
    border-radius: 11px;
    margin: 10px 10px;
    padding: 10px;
    height: 486px;
    overflow:hidden;
    position: relative;
  }
  .demand-content{
    font-size: 19px;
    margin: 10px 45px;
    font-weight: 550;
    letter-spacing: 2px;
  }
  .title-color{
    font-size: 22px;
    color: #AC010A;
    font-weight: 550;
  }
  .copyright{
    margin: 5px auto;
    width: 380px;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    color: #FFFFFF;
    opacity: 0.3;
  }
  .box-left{
    width: 70%;
  }

  .box-right{
    width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .box-right-title{
    font-size: 28px;
    font-weight: bold;
    color: #AC010A;
    margin-top: 20px;
  }
  .contact{
    width: 215px;
    max-height: 84px;
    margin: 20px;
    font-weight: bold;
    font-size: 21px;
    overflow: hidden;
    position: relative;
  }
  .wechat-qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #AC010A;
    color: #FFFFFF;
    padding: 4px;
    box-shadow: 0 2px 9px 1px rgba(0,27,75,0.42);
    border-radius: 4px;
  }
  .qrcode-title{
    font-weight: bold;
    padding: 5px;
    font-size: 18px;
  }
  .demand-content-box{
    margin-top: 20px;
  }
  .foot-info{
    width: 650px;
    color: #FFFFFF;
    text-align: center;
    margin: 20px auto;
  }
  .foot-info-title{
    margin-bottom: 15px;
    font-size: 25px;
  }
  .foot-info-content{
    font-size: 15px;
  }
  .shadow {
    box-shadow: 0 1pt 5pt 1.5pt rgba(192, 195, 207, 0.2);
    background: white;
  }
</style>
 
