<template>
  <div class="page">
    <el-menu style="width: 100%" default-active="1" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="1">需求发布</el-menu-item>
      <el-menu-item index="2">需求列表</el-menu-item>
    </el-menu>
    <div class="form">
      <div class="title">需求发布</div>
      <el-form
          :model="form"
          :rules="rules"
          ref="ruleFormRef"
      >
        <el-form-item label="企业名称" required prop="company_name">
          <el-input v-on=" { change:companyNameChange } " v-model="form.company_name" placeholder="请输入企业名称" />
        </el-form-item>
        <el-form-item label="需求方向" required prop="title">
          <el-input maxlength="100" show-word-limit type="textarea" v-model="form.title" placeholder="请输入需求方向" />
        </el-form-item>
        <el-form-item label="需求介绍" required prop="desc">
          <el-input maxlength="300" show-word-limit v-model="form.desc" type="textarea" placeholder="请输入需求介绍" />
        </el-form-item>
        <el-form-item label="联系方式" required prop="phone">
          <el-input maxlength="100" show-word-limit v-model="form.phone" type="textarea" placeholder="请输入联系方式 例：157xxxxxxxx - 张三" />
        </el-form-item>
        <el-form-item label="微信二维码" prop="qrcode">
          <el-upload
              class="avatar-uploader"
              action="https://api.yaoyouzhijia.com/api/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.qrcode" :src="form.qrcode" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
          <span class="tps">（为了统一格式请在手机微信 -> 我的 -> 二维码名片中保存上传二维码。）</span>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 120px;margin: 0 auto;margin-top: 50px" round type="primary" @click="onSubmit"> 提交 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="copyright">Copyright © 药友之家, All Rights Reserved. 成都卓康商务信息咨询有限公司 版权所有 蜀ICP备14002080号</div>
  </div>
</template>

<script setup>
  import { reactive, ref, onMounted } from 'vue'
  import axios from 'axios'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { Plus } from '@element-plus/icons-vue'

  const ruleFormRef = ref();

  const rules = reactive({
    company_name: [
      { required: true, message: '请输入企业名称', trigger: 'blur' },
    ],
    title: [
      { required: true, message: '请输入需求方向', trigger: 'blur' },
    ],
    desc: [
      { required: true, message: '请输入需求介绍', trigger: 'blur' },
    ],
    phone: [
      { required: true, message: '请输入联系方式', trigger: 'blur' },
    ],
    })

  // do not use same name with ref
  const form = reactive({
    company_name: '',
    title: '',
    desc: '',
    phone: '' ,
    qrcode: ''
  })

  const onSubmit = () => {
    ruleFormRef.value.validate((res) => {
      if (res) {
        axios.post('demand/add_demand',form).then((res)=>{
          if (res.data.code == 200){
            ruleFormRef.value.resetFields()
            form.qrcode = '';
            ElMessageBox.alert(res.data.msg, '提示', {
              confirmButtonText: '继续发布'
            })
          }else {
            ElMessage.error(res.data.msg);
          }
        })
      }
    });
  }

  const companyNameChange = (value) => {
    axios.get('demand/match_company?company_name=' + value).then((res)=>{
      if (res.data.code == 200 && res.data.data){
        const data = res.data.data;
        form.qrcode = data.qrcode;
        form.phone = data.phone;
      }
    })
  }

  const beforeAvatarUpload = (file) => {
    if (file.size / 1024 / 1024 > 2) {
      ElMessage.error('二维码大小不能超过2M！')
      return false
    }
    return true
  }

  const handleAvatarSuccess = (res) => {
    console.log(res)
    if (res.code == 200){
      form.qrcode = res.data.url
      ElMessage.success(res.msg)
    }else {
      ElMessage.error(res.msg)
    }
  }

  const handleSelect = (key, keyPath) => {
    console.log(key, keyPath);
  }

  onMounted(() => {
    ElMessageBox.alert('提交表单前，请先到微信->我的中保存微信二维码名片。', '提示', {
      confirmButtonText: '确定'
    })
  });
</script>

<style scoped>
  .page{
    width: 100%;
    /*height: 100%;*/
    position: absolute;
    background: #f1f1f1f1;
  }
  *{
    margin: 0;
    padding: 0;
  }
  .el-form-item{
    display: flex;
    font-size: 14px;
    margin-bottom: 18px;
  }
  .title{
    text-align: center;
    margin-bottom: 20px;
  }
  .form{
    padding: 25px 20px;
    margin: 25px 20px;
    border-radius: 15px;
    background: #ffffff;
    position: relative;
  }
  .copyright{
    margin: 0 auto;
    width: 280px;
    text-align: center;
    font-size: 1px;
    line-height: 16px;
    color: black;
    opacity: 0.3;
    position: relative;
    bottom: -80px;
  }
  .avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.tps{
  margin-left: 15px;
  color: #a8abb2;
}
</style>