<template>
  <div id="app"  v-wechat-title="$route.meta.title">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style scoped>

</style>
