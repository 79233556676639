import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementPlus from 'element-plus'
import vue3SeamlessScroll from "vue3-seamless-scroll";
import axios from 'axios'
import 'element-plus/dist/index.css'
import './assets/fonts/fonts.css'
import VueWechatTitle from 'vue-wechat-title';



const app = createApp(App)
app.config.globalProperties.$axios = axios
app.use(ElementPlus)
app.use(router)
app.use(vue3SeamlessScroll)
app.use(VueWechatTitle);
axios.defaults.baseURL='https://api.yaoyouzhijia.com/api/'
// axios.defaults.baseURL='http://uniapp.api.test/api/'



app.mount('#app')

